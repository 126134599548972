
















import { Component, Vue, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import _ from 'lodash';
import WorkflowService from '@/services/WorkflowService';
import {Proposal} from '@/models';

@Component({ name: 'PaymentIssue' })
export default class PaymentIssue extends Vue {
  @Prop() private submitApplication!: any;
  @State private app: any;
  @State private cms: any;
  @State private proposal!: Proposal;

  private async sendTask() {
    const context = {
      invoiceId: this.proposal.invoiceId
    };
    return WorkflowService.runWorkflowSync('create-payment-assistance-task', context, ['task']);
  }

  private sendTaskAndSubmit() {
    this.sendTask().then(() => {
      this.submitApplication();
      return;
    })
    .catch(() => {
      this.$dialog.open({ type: 'technical-issue', info: 'PaymentIssueForm - sendTaskAndSubmit' });
    });
  }

  private onClickHere() {
    this.$dialog.open({
      icon: _.get(this.cms, 'theme.successIcon'),
      text: this.$t('proposal.payment.information.dialogueText'),
      buttons: [
        {
          text: this.$t('proposal.payment.information.closeDialogue'),
        },
        {
          type: 'main',
          text: this.$t('proposal.payment.information.actionDialogue'),
          onClick: this.sendTaskAndSubmit
        }
      ]
    });
  }
}
